cart-drawer form {
  position: relative;
}

cart-drawer form .loading__spinner {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 2;
}

.spinner {
  animation: rotator 1.4s linear infinite;
  width: 20px;
  display: inline-block;
  margin-left: 10px;
}

.path {
  stroke-dasharray: 280;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: white;
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
  0% {
      stroke-dashoffset: 280;
  }
  50% {
      stroke-dashoffset: 75;
      transform: rotate(135deg);
  }
  100% {
      stroke-dashoffset: 280;
      transform: rotate(450deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0);
  }
  100% {
      transform: rotate(270deg);
  }
}

#product-form__buy-btn .original-price {
  color: rgb(108 96 101 / var(--tw-text-opacity)) !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  margin-left: 10px !important;
}

#collection-products .original-price {
  color: rgb(108 96 101 / var(--tw-text-opacity)) !important;
  font-size: 14px !important;
}

.cursor-zoom-in.zoom-active {
  cursor: zoom-out;
}

.product-image-zoom {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  width: 50px;
  height: 50px;
  pointer-events: none;
  display: none;
  background-color: #ccc;
  opacity: .7;
  backdrop-filter: 4px;
  -webkit-backdrop-filter: 4px;
  border-radius: 50%;
  align-items: center;
  justify-content: center
}

.product-image-zoom svg {
  display: block;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

@media(min-width: 1024px) {
  .product-image-zoom {
    display: flex;
  }
}

.main-product .splide__slide img {
  background: white;
}

.cart-drawer.h-screen {
  height: 100dvh;
}